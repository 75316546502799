<template>
    <location-form ref="form" @save="save" />
</template>

<script>
import LocationForm from '../LocationForm'
import httpHelper from '../../utils/modules/http.helper'
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'CreateLocationView',
    components: {
        LocationForm,
    },
    computed: {
        ...mapGetters('authentication', ['isAdminOrStaff']),
        changed() {
            return this.locationChanged || this.permissionsChanged
        },
        locationChanged() {
            const {
                name,
                description,
                geofence_tolerance,
            } = this.$refs.form.getValues()
            return (
                name ||
                description ||
                this.$refs.form.polygonDrawn ||
                geofence_tolerance
            )
        },
        permissionsChanged() {
            const {
                usersViewingAccess,
                usersEditingAccess,
            } = this.$refs.form.getPermissions()
            return (
                (usersViewingAccess && usersViewingAccess.length) ||
                (usersEditingAccess && usersEditingAccess.length)
            )
        },
    },
    mounted() {
        this.resetLocationPolygon()
    },
    methods: {
        ...mapMutations('createLocation', ['resetLocationPolygon']),
        ...mapMutations('locations', ['createLocation']),
        save(values) {
            this.$refs.form.setLoading(true)
            httpHelper.post('/locations/', values).then(res => {
                this.createLocation(res.data)
                this.saved = true
                this.$refs.form.setLoading(false)
                this.$refs.form.saved(res.data.id)
                if (this.isAdminOrStaff) {
                    this.manageAccess(res.data.id)
                }
            })
        },
        async manageAccess(id) {
            const {
                usersViewingAccess,
                usersEditingAccess,
            } = this.$refs.form.getPermissions()

            usersViewingAccess.forEach(user => {
                httpHelper.post('/location-permissions/', {
                    location: id,
                    user,
                    access: 'READ',
                })
            })

            usersEditingAccess.forEach(user => {
                httpHelper.post('/location-permissions/', {
                    location: id,
                    user,
                    access: 'WRITE',
                })
            })
        },
    },
    beforeRouteLeave(to, from, next) {
        if (!this.changed || this.saved) {
            next()
            return
        }

        this.$refs.form.confirmLeave(this.$t('confirmLeave'), confirmed => {
            next(confirmed)
        })
    },
}
</script>

<i18n>
    {
        "en": {
            "confirmLeave": "Really discard inputs?"
        },
        "de": {
            "confirmLeave": "Eingaben wirklich verwerfen?"
        }
    }
</i18n>

<style scoped></style>
